import { Spacer } from 'design-system';
import Image from 'next/image';

import { Timer } from './Timer';

/**
 * 지정된 날짜가 몇 주차인지 계산하여 반환합니다
 */
const getWeek = (date: Date) => {
  const currentDate = date.getDate();
  const firstDay = new Date(date.setDate(1)).getDay();

  return Math.ceil((currentDate + firstDay) / 7);
};

const getMonth = (date: Date) => {
  const month = date.getMonth() + 1;
  return month;
};

export const firstComeLabel = `${getMonth(new Date())}월 ${getWeek(new Date())}주차`;

export const Title = () => {
  return (
    <section className='flex flex-col items-center bg-black'>
      <Spacer className='h-[64px]' />
      <Image
        src='/events/first-come/red-lightning.png'
        width={22}
        height={32}
        alt='빨간 번개 이미지'
      />
      <Spacer className='h-[10px]' />
      <h1 className='font-extraBold text-center text-[40px] leading-[49.6px] text-white'>
        {firstComeLabel}
        <br />
        <span className='text-new-DTRed-400'>선착순 할인</span> 이벤트
      </h1>
      <Spacer className='h-[10px]' />
      <p className='text-new-Body1-medium text-center text-white text-opacity-50'>
        매주 운전선생이 학원을 선정하여
        <br />
        선착순 최대 4만원 할인 쿠폰을 뿌립니다!
      </p>
      <Spacer className='h-[32px]' />
      <Image
        src='/events/first-come/red-coupon.png'
        width={192}
        height={112}
        alt='빨간 쿠폰 이미지'
      />
      <Spacer className='h-[32px]' />
      <Timer />
      <Spacer className='h-[42px]' />
    </section>
  );
};
