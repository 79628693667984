import { useEffect, useState } from 'react';

import { useMounted } from '@hooks';

const getTimeUntilNextMonday = () => {
  const now = new Date();
  const dayOfWeek = now.getDay();
  const daysUntilNextMonday = dayOfWeek === 1 ? 7 : (8 - dayOfWeek) % 7; // 다음 월요일까지 남은 일 수 (오늘을 포함하지 않으므로 +1)

  // 다음 월요일 0시 계산
  const nextMonday = new Date(now);
  nextMonday.setDate(now.getDate() + daysUntilNextMonday);
  nextMonday.setHours(0, 0, 0, 0);

  // 남은 시간 계산
  const timeDifference = nextMonday.getTime() - now.getTime();

  // 밀리초를 일, 시간, 분으로 변환
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    .toString()
    .padStart(2, '0');
  const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
    .toString()
    .padStart(2, '0');

  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
    .toString()
    .padStart(2, '0');

  // 형식에 맞게 문자열로 반환
  return [hours, minutes, days, seconds];
};

function useGetTimeUntilNextMonday() {
  const [hours, minutes, days, seconds] = getTimeUntilNextMonday();

  const [HH, setHH] = useState(seconds);
  const [MM, setMM] = useState(minutes);
  const [DD, setDD] = useState(days);
  const [SS, setSS] = useState(seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      const [hours, minutes, days, seconds] = getTimeUntilNextMonday();
      setHH(hours);
      setMM(minutes);
      setDD(days);
      setSS(seconds);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return [HH, MM, DD, SS];
}

export const Timer = () => {
  const mounted = useMounted();
  const [HH, MM, DD, SS] = useGetTimeUntilNextMonday();

  if (!mounted) return <div className="h-[40px]" />;

  return (
    <div className="flex gap-8">
      <div className="flex items-center gap-4">
        <div className="py-13 text-new-Section-Title bg-new-gray-900 text-new-DTRed-400 rounded-[6px] px-7">
          {DD[0]}
        </div>
        <div className="py-13 text-new-Section-Title bg-new-gray-900 text-new-DTRed-400 rounded-[6px] px-7">
          {DD[1]}
        </div>
        <p className="text-new-white-50 text-new-Caption1-bold">일</p>
      </div>
      <div className="flex items-center gap-4">
        <div className="py-13 text-new-Section-Title bg-new-gray-900 text-new-DTRed-400 rounded-[6px] px-7">
          {HH[0]}
        </div>
        <div className="py-13 text-new-Section-Title bg-new-gray-900 text-new-DTRed-400 rounded-[6px] px-7">
          {HH[1]}
        </div>
        <p className="text-new-white-50 text-new-Caption1-bold">시</p>
      </div>
      <div className="flex items-center gap-4">
        <div className="py-13 text-new-Section-Title bg-new-gray-900 text-new-DTRed-400 rounded-[6px] px-7">
          {MM[0]}
        </div>
        <div className="py-13 text-new-Section-Title bg-new-gray-900 text-new-DTRed-400 rounded-[6px] px-7">
          {MM[1]}
        </div>
        <p className="text-new-white-50 text-new-Caption1-bold">분</p>
      </div>
      <div className="flex items-center gap-4">
        <div className="py-13 text-new-Section-Title bg-new-gray-900 text-new-DTRed-400 rounded-[6px] px-7">
          {SS[0]}
        </div>
        <div className="py-13 text-new-Section-Title bg-new-gray-900 text-new-DTRed-400 rounded-[6px] px-7">
          {SS[1]}
        </div>
        <p className="text-new-white-50 text-new-Caption1-bold">초</p>
      </div>
    </div>
  );
};
